<template>
  <div class='platform-platformInfo mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
      <div class="pageCont" :style="{'height':mapHeight}">
        <div class="title_name">
          <div>平台信息</div>
          <div>
            <!-- <el-button type="text" @click="checkForm('dioform')"><i class="el-icon-s-promotion"></i>保存</el-button> -->
          </div>
        </div>
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" class="mg20" size="small">
          <!-- <el-form-item label="LOGO:">
            <el-upload
              class="uploader"
              action="#"
              :show-file-list="false"
              :http-request="httpRequest"
              :before-upload="beforeUpload"
            >
              <img v-if="inform.logoPicImg" :src="inform.logoPicImg" class="img">
              <i v-else class="iconJia el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">注：建议上传200x200的图片</div>
            </el-upload>
          </el-form-item> -->
          <!-- <el-form-item label="ICON:" prop="titIcon">

          </el-form-item> -->
          <el-form-item label="平台名称:" prop="appShowName">
            <el-input type="text" v-model="inform.appShowName" placeholder="请输入平台名称" class="w300"></el-input>
          </el-form-item>
          <el-form-item label="版权所有:" prop="copyright">
            <el-input type="text" v-model="inform.copyright" placeholder="请输入版权所有" class="w300"></el-input>
          </el-form-item>
          <el-form-item label="平台描述:" prop="appDescribe">
            <el-input type="textarea" :rows="4" v-model="inform.appDescribe" placeholder="请输入描述"></el-input>
          </el-form-item>
          <el-form-item label="网站ICON:">
            <el-upload
              class="uploader"
              action="#"
              :show-file-list="false"
              :http-request="httpRequest2"
              :before-upload="beforeUpload2"
            >
              <img v-if="inform.iconPicImg" :src="inform.iconPicImg" class="img">
              <i v-else class="iconJia el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="网站标题:" prop="siteTitle">
            <el-input v-model="inform.siteTitle" placeholder="请输入网站标题" class="w300"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="checkForm('dioform')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import { apiUrl,globalStr } from '@/assets/js/api';
import qs from 'qs'
var vm;
export default {
  name:'platform-platformInfo',
  data() {
    return {
      inform :{
        id:'',
        logoPicImg:'',//logo显示
        logoFile:'',//logo文件
        appShowName:'',//平台名称
        copyright:'v1.0',//版本号
        appDescribe:'',//描述
        iconPicImg:'',//icon显示
        iconFile:'',//icon文件
        siteTitle:'',//网站标题
      },
      //验证form格式
      rules :{
        appShowName: [
          { required: true, message: '请输入平台名称', trigger: 'blur' },
        ],
      },
      mapHeight :'calc(100vh - 113px)',

      // const dicObj = reactive({
      //   typeData:[],//类型
      // })
    }
  },
  components:{
    breadCrumb
  },
  created(){
    vm = this
  },
  mounted(){
    vm.getAppVo()
  },


  //方法
  methods:{
    //查询当前登录应用的详细数据
    async getAppVo (){
      let dataObj = {
        'appCode':'APP_SECURITY_PC'
      }
      const res = await apiUrl.getAppVo(qs.stringify(dataObj))
      if(res?.code==200){
        vm.inform.id = res.data.id
        vm.inform.logoPicImg = res.data.logoFile?`${globalStr}/fileView${res.data.logoFile.fileRelativePath}`:''
        vm.inform.appShowName = res.data.appShowName
        vm.inform.copyright = res.data.copyright
        vm.inform.appDescribe = res.data.appDescribe
        vm.inform.iconPicImg = res.data.iconFile? `${globalStr}/fileView${res.data.iconFile.fileRelativePath}`:''
        vm.inform.siteTitle = res.data.siteTitle
      }else{
        vm.$message.error(res.message)
      }
    },
    // 上传之前的格式设置
    beforeUpload (file){
      // console.log('file:',file);
      // const isJPG = file.type ==='image/jpeg'
      // if(file && type == 'logo'){
        const isPNG = file.type ==='image/png' || file.type === 'image/jpg'||file.type === 'image/jpeg'
        const isLt500M = file.size / 1024 / 1024 < 500
        if (!isPNG) {
          vm.$message.warning('上传图片只能是JPG、JPEG或PNG 格式!')
        }
        if (!isLt500M) {
          vm.$message.warning('上传图片大小不能超过 500MB!')
        }
        return isPNG && isLt500M
    },
    beforeUpload2 (file){
      // console.log('file:',file);
      const isIcon = file.type ==='image/x-icon'
      const isLt500M = file.size / 1024 / 1024 < 500
      if (!isIcon) {
        vm.$message.warning('上传图片只能是ICO格式!')
      }
      if (!isLt500M) {
        vm.$message.warning('上传头像图片大小不能超过 500MB!')
      }
      return isIcon && isLt500M
      
    },
    httpRequest (data){
      // console.log(data);
      vm.inform.logoFile = data.file
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      // // vm.inform.headPic = file
      // rd.readAsBinaryString(file)// 文件读取装换为二进制类型
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        // console.log(e);
        vm.inform.logoPicImg = this.result// this指向当前方法onloadend的作用域
        
      }
    },
    httpRequest2 (data){
      // console.log('httpRequest2',data);
      vm.inform.iconFile = data.file
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      // // vm.inform.headPic = file
      // rd.readAsBinaryString(file)// 文件读取装换为二进制类型
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        // console.log(e);
        // console.log('this.result',this.result);
        vm.inform.iconPicImg = this.result// this指向当前方法onloadend的作用域
        
      }
    },
    //校验form
    async checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          vm.updateApp()
        } else {return false}
      })
    },
    //根据应用Id更新应用信息
    async updateApp (){
      let dataObj = new FormData();
      dataObj.append("id", vm.inform.id);
      if(vm.inform.logoFile){
        dataObj.append("logoFile", vm.inform.logoFile||'');
      }
      if(vm.inform.iconFile){
        dataObj.append("iconFile", vm.inform.iconFile||'');
      }
      dataObj.append("appShowName", vm.inform.appShowName||'');
      dataObj.append("copyright", vm.inform.copyright||'');
      dataObj.append("appDescribe", vm.inform.appDescribe||'');
      dataObj.append("siteTitle", vm.inform.siteTitle||'');
      
      const res = await apiUrl.updateApp(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        document.title = vm.inform.siteTitle
        vm.changeFavicon(vm.inform.iconPicImg)
        vm.$store.state.appShowName = vm.inform.appShowName
        
        let platInfo = vm.vtp.get('platInfo')
        if(platInfo){
          platInfo.appShowName = vm.inform.appShowName
          platInfo.siteTitle = vm.inform.siteTitle
          vm.vtp.set('platInfo',platInfo)
        }
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改网站icon
    changeFavicon (url) {
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) {
        $favicon.href = url;
      } else {
        $favicon = document.createElement("link");
        $favicon.rel = "icon";
        $favicon.type = "image/x-icon";
        $favicon.href = url;
        document.head.appendChild($favicon)
      }
    },
  }
}
</script>
<style lang='scss'>
.platform-platformInfo{
  .el-form{
    .uploader{
      .el-upload__tip{
        color: #666;
        line-height: 1;
      }
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
          border-color: #409EFF;
        }
        .iconJia{
          font-size: 28px;
          color: #8c939d;
          width: 116px;
          height: 116px;
          line-height: 116px;
          text-align: center;
        }
        .img {
          width: 116px;
          height: 116px;
          display: block;
        }
      }
    }
  }
}
</style>
